import React, { useRef, useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import { media } from '@tymate/margaret';
import { ButtonReset } from 'ui';
import { MdPlayCircleOutline, MdPauseCircleOutline } from 'react-icons/md';
import VideoPlayer from 'components/VideoPlayer';

const PlayButton = styled(ButtonReset)`
  width: 100%;
  margin-top: 0;
  ${media.tablet`
  margin-top: ${({ theme }) => theme.spacing(3)};`}
`;

const VideoTextContent = styled.div`
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  width: 100%;
  left: 0;
  z-index: 100;

  ${({ hasNoPlayButton }) =>
    hasNoPlayButton &&
    css`
      top: 30% !important;
    `}
`;

const VideoPlayerWrapper = styled.div`
  video {
    height: 100vh !important;
    width: 100vw;
    object-fit: cover;
  }
`;

const Image = styled(GatsbyImage)`
  height: calc(100vh - 60px);
`;

const SvgWrapper = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing()};
`;

const MainSvg = styled.img`
  flex: 1;
  width: 350px;
  background-size: contain;

  ${media.tablet`
    max-width: 470px;
  `}
`;

const Aside = styled.aside`
  flex: 1;
`;

const PlayAction = styled.div`
  width: fit-content;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    margin-top: ${({ theme }) => theme.spacing()};
    font-size: 24px;
    color: #fff;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: auto;
  overflow: hidden;
  height: 100vh;

  ${props =>
    props.isPlaying &&
    css`
      svg {
        visibility: hidden;
      }

      &:hover {
        svg {
          visibility: initial;
        }
      }
    `};
`;

const FallbackTitle = styled.div`
  font-size: 36px;
  color: #fff;
  text-transform: capitalize;
  font-weight: 900;
  line-height: 100px;

  ${media.tablet`
  font-size: 128px;
  `}
`;

const VideoTitle = styled.h1`
  margin: 0;
  font-size: 18px;
  color: #fff;
  font-weight: 700;

  ${media.tablet`
  font-size: 36px;
  `}
`;

const SVG = styled.div`
  height: 100px;
  font-size: 50px;

  ${media.tablet`
  font-size: 100px;
  `}
`;

const Video = ({ blockVideoFullPage, hasNoPlayButton }) => {
  const { text, mainTitle, mainSvg, videoPhoto, poster, autoplay } =
    blockVideoFullPage || {};
  const [playbackState, setPlaybackState] = useState();
  const videoRef = useRef();

  return (
    <ImageWrapper isPlaying={playbackState === 'playing'}>
      {videoPhoto ? (
        <>
          <VideoPlayerWrapper>
            <VideoPlayer
              ref={videoRef}
              poster={poster?.url}
              video={videoPhoto?.video}
              autoPlay={autoplay}
              onPlaybackState={setPlaybackState}
            />
          </VideoPlayerWrapper>

          <VideoTextContent hasNoPlayButton={hasNoPlayButton}>
            {Boolean(mainSvg) ? (
              <SvgWrapper>
                <Aside />
                <MainSvg src={mainSvg?.url} />
                <Aside />
              </SvgWrapper>
            ) : (
              <FallbackTitle>{mainTitle}</FallbackTitle>
            )}
            <VideoTitle>{text}</VideoTitle>
            {!hasNoPlayButton && videoPhoto?.video && (
              <PlayButton
                onClick={e => {
                  e.preventDefault();
                  videoRef.current.onTogglePlay();
                }}
              >
                {playbackState === 'playing' ? (
                  <SVG>
                    <MdPauseCircleOutline color="#fff" />
                  </SVG>
                ) : (
                  <PlayAction>
                    <SVG>
                      <MdPlayCircleOutline color="#fff" />
                    </SVG>
                  </PlayAction>
                )}
              </PlayButton>
            )}
          </VideoTextContent>
        </>
      ) : (
        <>
          <Image picture={poster?.gatsbyImageData} />
          <VideoTextContent>
            <FallbackTitle>{mainTitle}</FallbackTitle>
            <VideoTitle>{text}</VideoTitle>
          </VideoTextContent>
        </>
      )}
    </ImageWrapper>
  );
};

export default Video;
